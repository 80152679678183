import React from "react";
import Image from "react-bootstrap/Image";

import { TruncateBody } from "./utils";

const RssBlock = (props) => {

    return <ul className="list-unstyled">
        {props.items.map(item => {
            return <li key={item.guid.text}>
                <div>
                    <a className="small" href={item.link2} title={item.title}>
                        {item.thumbnail !== null && <Image width="40" height="40" src={`${item.thumbnail.url}?w=40&resize=40,40`
                        } className="feed-image" />}
                        {item.thumbnail !== null && <div className="feed-text">
                            {TruncateBody(item.title, props.textLength)}
                        </div>}
                        {item.thumbnail === null && <div className="feed-text" style={{ marginLeft: 60 }}>
                            {TruncateBody(item.title, props.textLength)}
                        </div>}
                    </a>
                </div>
            </li>;
        })}
    </ul>;
};
export default RssBlock;