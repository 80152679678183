import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { getAllData, deleteById } from "../shared/api-requests";
import { DateFormat } from "../shared/utils";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TableBasic from "../shared/table-basic";

const ManageUsers = () => {
    const [data, setData] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [rowSelected, setRowSelected] = useState(null);

    const handleCloseDelete = () => {
        setRowSelected(null);
        setShowDelete(false);
    };

    const handleShowDelete = (row) => {
        setRowSelected(row);
        //console.log(row);
        setShowDelete(true);
    };

    const loadUsers = async () => {
        try {
            const resp = await getAllData("/api/userAdmin");
            setData(resp);

        } catch (error) {
            console.log(error);
        }
    };

    const deleteUser = async () => {
        try {
            await deleteById("/api/userProfile", rowSelected.id);
            loadUsers();
            setShowDelete(false);
        } catch (e) {
            setShowDelete(false);
            console.log(e);
        }
    };

    useEffect(() => {
        loadUsers();
    }, []);

    const dateCell = (dt) => {
        if (dt.cell.value === null) {
            return null;
        }
        return <span style={{ display: "inline-block", minWidth: 130 }}><DateFormat date={dt.cell.value} /></span>;
    };

    const actionCell = (dt) => {
        return <span>
            <Link to={`/user/${dt.cell.value}`}><Button variant="link" className="pt-0">View</Button></Link>{" "}
                   <Button variant="link" className="pt-0" onClick={()=> {handleShowDelete(dt.row.original)}}>Delete</Button>
        </span>;
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Full name",
                accessor: "fullName"
            },
            {
                Header: "Username",
                accessor: "userName"
            },
            {
                Header: "Created",
                accessor: "created",
                disableFilters: true,
                Cell: dateCell
            },
            {
                Header: "Last login",
                accessor: "lastLogin",
                disableFilters: true,
                Cell: dateCell
            },
            {
                Header: "Actions",
                accessor: "id",
                disableFilters: true,
                disableSortBy: true,
                Cell: actionCell
            }
        ],
        []
    );


    return <div className="full-height-reset">
               <Modal show={showDelete} onHide={handleCloseDelete}>
                   <Modal.Header closeButton>
                       <Modal.Title>Delete user</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>Are you sure you want to delete this user?<p>{rowSelected !== null ? rowSelected.fullName: ""}</p></Modal.Body>
                   <Modal.Footer>
                      
                       <Button variant="danger" onClick={deleteUser}>
                          Delete
                       </Button>
                       <Button variant="secondary" onClick={handleCloseDelete}>
                           Cancel
                       </Button>
                   </Modal.Footer>
               </Modal>
               <Row>
                   <Col md={12}>
                       <h2>Users</h2>
                   </Col>
               </Row>
            <Row>
            <Col md={12}>
                <TableBasic columns={columns} data={data}/>
            </Col>
        </Row>
       </div>;
};



export default ManageUsers;