import React from "react";
import { Link } from "react-router-dom";

const AuthorLink = (props) => {
   
    if (props.author.fullName === "Anonymous") {
        return <span>{props.author.fullName}</span>;
    }
    let pathStart = "user";
    if (props.author.role === ("AskaPg.Models.Mentor")) {
        pathStart = "mentor";
    }
    return <Link to={`/${pathStart}/${props.author.id}`}>{props.author.fullName}</Link>;
};

export default AuthorLink;