import React from "react";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import { DateFormat } from "./utils";
import { HashLink } from "react-router-hash-link";


//<HashLink smooth to={`/question/${f.questionId}#${props.itemType}-${f.itemId}`}>{f.questionTitle}</HashLink>

const MentionBlock = (props) => {
    let lastLogin = new Date();
    if (props.previousLogin !== null) {
        //console.log(props.previousLogin);
        lastLogin = new Date(props.previousLogin);
    }

    const linkStrings = {
        Question: "Question",
        QuestionComment: "Question comment",
        Answer: "Answer",
        AnswerComment: "Answer comment",
    };

    const buildLink =  (mention) => {
        // The first part of a link is the same
        // in all cases
        const link = `/question/${mention.questionId}`;
        switch(mention.mentionType) {
        case "Question":
            return link;
        case"Answer":
                return link + "#answer-" + mention.itemId;
        case"AnswerComment":
            return link + "#answercomment-" + mention.itemId;
        case"QuestionComment":
            return link + "#questioncomment-" + mention.itemId;
        }

        return link;
    };
    return <Table size="sm">
        <thead>
            <tr>
                <th>Question</th>
                <th>Mentioned in</th>
                <th>Added</th>
            </tr>
        </thead>
        <tbody>
            {props.mentions.map(m => {
                return <tr key={m.id}>
                    <td>{m.linkText}</td>
                    <td><HashLink smooth to={buildLink(m)}>{linkStrings[m.mentionType]}</HashLink></td>
                    <td>
                        <DateFormat date={m.added} />
                        {" "}
                        {new Date(m.added) > lastLogin && <Badge variant="primary">New</Badge>}
                        </td>
                </tr>;
            })}
        </tbody>

    </Table>;

};

export default MentionBlock;