import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import MentionBlock from "./mention-block";

const MentorProfileBlock = (props) => {

    return <div className="full-height-reset">
        <Row>
            <Col md={{span:11, offset:1}}>
                <h3>{props.profile.fullName}</h3>
                {props.profile.notActive &&
                    <Alert variant="info">{props.profile.notActiveReason}</Alert>
                }
            </Col>
            <Col md={{span:4, offset:1}} className="mb-4">
                <Image src={`/images/${props.profile.imageName}?w=350&h=350&mode=crop`} fluid/>
            </Col>
            <Col md={6}>
                <dl>
                    <dt>Course, department</dt>
                    <dd>{props.profile.courseDepartment}</dd>
                    <dt>Year and mode of study</dt>
                    <dd>{props.profile.yearMode}</dd>
                    <dt>Home country</dt>
                    <dd>{props.profile.homeCountry}</dd>
                </dl>
                <div dangerouslySetInnerHTML={{ __html: props.profile.bio }}></div>
            </Col>
        </Row>
        {props.profile.answers.length > 0 &&
            <Col md={{span:10, offset:1}}>
                <h4>Answers</h4>
                <Table>
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Answered</th>
                            <th>Votes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.profile.answers.map((a, index) => {
                            return <tr key={index}><td><Link to={`/question/${a.questionId}`}>{a.title}</Link></td>
                                <td>{new Date(a.answerDate).toLocaleString([], { day: "numeric", year: "numeric", month: "numeric", hour: "2-digit", minute: "2-digit" })}</td>
                                <td>{a.answerVotes}</td></tr>;
                        })}
                    </tbody>
                </Table>
            </Col>
        }

        {props.profile.mentions.length > 0 &&
             <Col md={{span:10, offset:1}}>
                <h5>Latest mentions</h5>
                <MentionBlock mentions={props.profile.mentions} previousLogin={props.profile.previousLogin} />
            </Col>
        }
    </div>;
};

export default MentorProfileBlock;


