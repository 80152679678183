import { BehaviorSubject } from 'rxjs';

const currentUserSubject = new BehaviorSubject(localStorage.getItem('ask_token'));

export const authenticationService = {
    setUser,
    removeUser,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function setUser(token) {
    localStorage.setItem('ask_token', token);
    currentUserSubject.next(token);
}

function removeUser() {
    // remove user from local storage to log user out
    localStorage.removeItem('ask_token');
    currentUserSubject.next(null);
}
