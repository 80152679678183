import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { getAllData, deleteById, putBody } from "../shared/api-requests";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TableBasic from "../shared/table-basic";

const ManageTags = () => {
    const [data, setData] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [tagSelected, setTagSelected] = useState({ id: 0, value: "" });

    const handleCloseDelete = () => {
        setTagSelected({ id: 0, value: "" });
        setShowDelete(false);
    };

    const handleShowDelete = (row) => {
        const tagObj = { id: row.id, value: row.value }
        setTagSelected(tagObj);
        setShowDelete(true);
    };

    const handleCloseEdit = () => {
        setTagSelected({ id: 0, value: "" });
        setShowEdit(false);
    };

    const handleShowEdit = (row) => {
        const tagObj = { id: row.id, value: row.value }
        setTagSelected(tagObj);
        setShowEdit(true);
    };

    const loadTags = async () => {
        try {
            const resp = await getAllData("/api/tags");
            setData(resp);

        } catch (error) {
            console.log(error);
        }
    };

    const deleteTag = async () => {
        try {
            await deleteById("/api/tags", tagSelected.id);
            loadTags();
            setTagSelected({ id: 0, value: "" });
            setShowDelete(false);
        } catch (e) {
            setShowDelete(false);
            console.log(e);
        }
    };

    const editTag = async () => {
        try {
            await putBody("/api/tags", tagSelected);
            setShowEdit(false);
            setTagSelected({ id: 0, value: "" });
            loadTags();
        } catch (e) {
            setShowEdit(false);
            console.log(e);
        }
    };

    const handleTagValue = (event) => {
        var newTag = { ...tagSelected, value: event.target.value };
        setTagSelected(newTag);
    };

    useEffect(() => {
        loadTags();
    }, []);


    const actionCell = (dt) => {
        return <span>
            <Button variant="link" className="pt-0" onClick={() => { handleShowEdit(dt.row.original) }}>Edit</Button>
            <Button variant="link" className="pt-0" onClick={() => { handleShowDelete(dt.row.original) }}>Delete</Button>
        </span>;
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Tag",
                accessor: "value"
            },
            {
                Header: "Uses",
                accessor: "count"
            },
            {
                Header: "Actions",
                accessor: "id",
                disableFilters: true,
                disableSortBy: true,
                Cell: actionCell
            }
        ],
        []
    );

    return <div className="full-height-reset">
        <Modal show={showEdit} onHide={handleCloseEdit}>
            <Modal.Header closeButton>
                <Modal.Title>Edit tag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="tagValue">
                        <Form.Control value={tagSelected.value} onChange={handleTagValue} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={editTag}>
                    Submit
                </Button>
                <Button variant="secondary" onClick={handleCloseEdit}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
                <Modal.Title>Delete tag</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this tag?<p>{tagSelected.value}</p></Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={deleteTag}>
                    Delete
                </Button>
                <Button variant="secondary" onClick={handleCloseDelete}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
        <Row>
            <Col md={12}>
                <h2>Tags</h2>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <TableBasic columns={columns} data={data} />
            </Col>
        </Row>
    </div>;
};

export default ManageTags;