import React from "react";
import "tinymce/tinymce";
import "tinymce/icons/default";
//import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
//import "tinymce/plugins/image";
//import "tinymce/plugins/table";

import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = ({ contentEditor, handleEditorChange, initialValue, height }) => {
    if (height === undefined) {
        height = "500";
    }
    return <Editor
               initialValue={initialValue}
        init={{
            height: height,
            menubar: false,
            plugins: "link lists",
            external_plugins: {
                "mentions": "/js/mention-plugin.js"
            },
            toolbar: "undo redo | formatselect | " +
                "bold italic " +
                "| bullist numlist | outdent indent | link | " +
                "removeformat",
            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }"
        }}
        value={contentEditor}
        onEditorChange={handleEditorChange}
    />;
};

export default TextEditor;