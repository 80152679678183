const defaultCountry = "---Select a country---";

const buildForm = (profile, contentEditor) => {
    const formData = new FormData();

    if (profile.id !== undefined) {
        formData.append("id", profile.id);
    }

    if (profile.courseDepartment !== null) {
        formData.append("CourseDepartment", profile.courseDepartment);
    }
    if (profile.yearMode !== null) {
        formData.append("YearMode", profile.yearMode);
    }
    if (profile.homeCountry !== null && profile.homeCountry !== defaultCountry) {
        formData.append("HomeCountry", profile.homeCountry);
    }

    formData.append("UserName", profile.userName);
    formData.append("Email", profile.email);
    formData.append("NotifyMe", profile.notifyMe);
    
    if (profile.notActive !== undefined) {
        formData.append("NotActive", profile.notActive);
    }

    if (profile.notActiveReason !== undefined && profile.notActiveReason !== null) {
        formData.append("NotActiveReason", profile.notActiveReason);
    }

    if (profile.preferredFirstName !== undefined) {
        formData.append("preferredFirstName", profile.preferredFirstName);
    }

    if (profile.fullName !== undefined) {
        formData.append("FullName", profile.fullName);
    }

    if (contentEditor !== undefined && profile.bio!== undefined) {
        formData.append("Bio", contentEditor);
    }

    return formData;
}

export  {buildForm, defaultCountry};