import React, { useState, useEffect } from "react";
import { useParams, Redirect, useLocation, useHistory } from "react-router-dom";
import { getAllData } from "./shared/api-requests";
import MentorProfileBlock from "./shared/mentor-profile-block";
import { isAuthenticated } from "./shared/utils";

const MentorProfile = () => {
    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();
    const [profile, setProfile] = useState();

    const loadProfile = async () => {
        try {
            const data = await getAllData(`/api/mentorProfile?id=${id}`);
            setProfile(data);

        } catch (error) {
            console.log(error);
            if (error.response.status === 401) {
                return <Redirect to={`/login?returnUrl=${location.pathname}`} />;
            }
        }
    };

    useEffect(() => {
        if (!isAuthenticated()) {
            history.push(`/login?returnUrl=${location.pathname}`);
        } else {
            loadProfile();
        }
     
    }, []);

    
    return <div>
        {profile !== undefined &&
           <MentorProfileBlock profile={profile} />
            }
       
        </div>;
};

export default MentorProfile;