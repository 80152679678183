import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Contact = () => {
    return <Row>
        <Col md={{ span: 6, offset: 3 }}>
            <h2>Contact information</h2>
            <address>
                PG Hub<br />
                       Senate House<br />
                       University of Warwick<br />
                       Gibbet Hill Road<br />
                       Coventry<br />
                       CV4 7AL<br />
                       United Kingdom<br />

                       Telephone: +44 (0)24 7615 1956
                   </address>
            <address>
                <strong>Email:</strong>   <a href="mailto:pgcommunity@warwick.ac.uk">pgcommunity@warwick.ac.uk</a><br />
            </address>
        </Col>
    </Row>;
};

export default Contact;