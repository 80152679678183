import React from "react";
import jwt_decode from "jwt-decode";

export const DateFormat = (props) => {
    const dt = new Date(props.date).toLocaleString("en-GB",
        { day: "numeric", year: "numeric", month: "numeric", hour: "2-digit", minute: "2-digit" });
    return <span>{dt}</span>;
}

export const TruncateBody = (text, length) => {
    // const requiredLength = 140;
    const stripped = text.replace(/(<([^>]+)>)/gi, "");
    const noSpaces = stripped.replace(/\&nbsp;/g, " ");
    if (noSpaces.length > length) {
        const truncated = noSpaces.slice(0, length) + "...";
        return truncated;
    }
    return noSpaces;
};

export const roleUri = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

export const getToken = () => { 
    return localStorage.getItem("ask_token");
};

export const getAuth = () => {
    // get the JWT token
    const token = localStorage.getItem("ask_token");
    //console.log(token);
    if (token === undefined || token === null) {
        return false;
    }
    // decode it
    const decoded = jwt_decode(token);
    const timestamp = Math.round((new Date()).getTime() / 1000);
    const timeRemaining = decoded.exp - timestamp;
    // see if there are more than 10 minutes left
    // If not delete it
    if (timeRemaining > 600) {
        return true;
    } else {
        localStorage.removeItem("ask_token");
        return false;
    }
};

export const isAdmin = () => {
    
        const token = window.localStorage.getItem("ask_token");
        if (token === undefined || token === null) {
            return false;
        }

        const decoded = jwt_decode(token);
        //console.log(decoded);
        // see if it's an array of roles or just one
        const roles = decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        if (Array.isArray(roles)) {
            if (roles.includes("Admin")) {
                return true;
            }
        } else {
            if (roles === "Admin") {
                return true;
            }
        }
   
    return false;
};

export const userInfo = () => {
    const info = {
        admin: false,
        fullName: null,
        sub: null,
        role: null
    };
  
        const token = localStorage.getItem("ask_token");
        if (token === undefined || token === null) {
            return null;
        }

        const decoded = jwt_decode(token);
        info.fullName = `${decoded["given_name"]} ${decoded["family_name"]}`;
        info.sub = decoded["sub"];
        //http://schemas.microsoft.com/ws/2008/06/identity/claims/role
        info.roles = decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
       
        if (Array.isArray(info.roles)) {
            if (info.roles.includes("Admin")) {
                info.admin = true;
            }
        } else {
            if (info.roles === "Admin") {
                info.admin = true;
            }
        }

        return info;
};

export const setToken = (token) => {
    localStorage.setItem("ask_token", token);
};

export const decodeToken = (token) => {
    var decoded = jwt_decode(token);
    return decoded;
};

export const removeToken = () => {
    if (getToken() !== null) {
        window.localStorage.removeItem("ask_token");
    }
};


export const isAuthenticated = () => {
    // get the JWT token
    const token = localStorage.getItem("ask_token");
    if (token === undefined || token === null) {
        return false;
    }
    // decode it
    const decoded = jwt_decode(token);
    const timestamp = Math.round((new Date()).getTime() / 1000);
    const timeRemaining = decoded.exp - timestamp;
    // see if there are more than 10 minutes left
    // If not delete it
    if (timeRemaining > 600) {
        return true;
    } else {
        localStorage.removeItem("ask_token");
        return false;
    }
};

export const authHeader = () => {
    const token = localStorage.getItem("ask_token");
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Accept': "application/json, text/plain, */*",
        'Content-Type': "application/json"
    };
    return headers;
};

export const authHeaderBearer = (token) => {
    return {
        'Authorization': `Bearer ${token}`,
        'Accept': "application/json, text/plain, */*",
        'Content-Type': "application/json"
    }
};