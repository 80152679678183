import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {  useHistory } from "react-router-dom";
import { getAll } from "../shared/api-requests";
import axios from "axios";
import ProfileForm from "../shared/profile-form";
import { buildForm } from "../shared/form-data";

const MentorCreate = () => {

    const [userName, setUserName] = useState("");
    const [exists, setExists] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [profile, setProfile] = useState();
    const [showForm, setShowForm] = useState(false);
    const history = useHistory();
    const [photo, setPhoto] = useState();
    const [fileError, setFileError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    //const defaultCountry = "---Select a country---";
    const [contentEditor, setContentEditor] = useState("");
    const [formErrors, setFormErrors] = useState([]);

    const findUser = async (event) => {
        event.preventDefault();
        try {
            const response = await getAll(`/api/adLookup/?id=${userName}`);
            if (response.data.exists !== undefined) {
                setExists(true);
                setShowForm(false);
                //console.log(data.exists);
            }
            else if (response.status === 204) {
                setNotFound(true);
            } else {
                const initProfile = {
                    bio: null,
                    courseDepartment: null,
                    email: response.data.email,
                    fullName: response.data.fullName,
                    homeCountry: null,
                    preferredFirstName: response.data.preferredFirstName,
                    userName: response.data.userName,
                    yearMode: null,
                    notifyMe: true
                }

                setProfile(initProfile);
                setShowForm(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleUserName = (event) => {
        setExists(false);
        setNotFound(false);
        setShowForm(false);
        setUserName(event.target.value);
    };

    const onPhotoChange = event => {
        setFileError(false);
        setErrorMessage("");
        setPhoto(event.target.files[0]);
    };
    const validImages = ["image/jpg", "image/jpeg", "image/png"];

    // validate the form

    const validateForm = () => {
        // Fields required course/department, year mode, home country, bio
        const errors = [];
        if (profile.courseDepartment === null || profile.courseDepartment === "") {
            errors.push("Course, department");
        }
        if (profile.yearMode === null || profile.yearMode === "") {
            errors.push("Year and mode of study");
        }
        if (profile.homeCountry === null || profile.homeCountry === "") {
            errors.push("Home country");
        }
        if (contentEditor === null || contentEditor === "") {
            errors.push("Biography");
        }

        if (profile.email === null || profile.email === "") {
            errors.push("Preferred email address");
        }

        if (profile.preferredFirstName === null || profile.preferredFirstName === "") {
            errors.push("Preferred first name");
        }

        if (photo === undefined) {
            errors.push("Photo");
        }

        setFormErrors(errors);
        return errors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validateForm();
        if (errors.length > 0) {
            return;
        }

        const formData = buildForm(profile, contentEditor);
        if (photo !== undefined) {
            if (!validImages.includes(photo.type)) {
                setFileError(true);
                setErrorMessage("Not a valid image file");
                return;
            }
            formData.append("File", photo);
        }

        //formData.append("NotifyMe", true);

        const token = localStorage.getItem("ask_token");

        axios.post(`/api/mentorProfile`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${token}`
                }
            }).then(function (response) {
                history.push("/admin/mentors");
            })
            .catch(function (error) {
                setFileError(true);
                setErrorMessage(error.response.data.message);
                console.log(error);
            });
    };


    return <div><Row>
        <Col md={{ span: 6, offset: 3 }}>
            <h2>Add mentor</h2>
            {formErrors.length > 0 &&
                <Alert variant="warning">
                    <ul>
                        {formErrors.map(e => { return <li key={e}>{e} is required</li>; })}
                    </ul>
                </Alert>
            }

            <Form onSubmit={findUser}>
                <Form.Group controlId="userName">
                    <Form.Label>ITS username</Form.Label>
                    <Form.Control type="text" value={userName} name="userName" onChange={handleUserName} />
                    <Form.Text className="text-muted">
                        Search for user by username.
                    </Form.Text>
                </Form.Group>
                <Button type="submit">Search</Button>
            </Form>
            {exists && <Alert variant="warning" className="mt-2">{userName} is already registered</Alert>}
            {notFound && <Alert variant="warning" className="mt-2">{userName} not found</Alert>}
        </Col>
    </Row>
        {showForm &&
            <ProfileForm
                userType="mentor"
                profile={profile}
                handleSubmit={handleSubmit}
                setProfile={setProfile}
                setContentEditor={setContentEditor}
                contentEditor={contentEditor}
                onPhotoChange={onPhotoChange}
                fileError={fileError}
                errorMessage={errorMessage}
            />
        }
    </div>;
};

export default MentorCreate;