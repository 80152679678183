import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Background = () => {
    return <Row>
               <Col md={{span:8, offset:2}} >
                   <h2>About Postgrad mentoring</h2>

                   <p>
                       The PG Community Engagement Team (University of Warwick Library) has created, and will facilitate, a peer-support network with online and face-to-face aspects – therefore,
                       offering support on and off campus to suit individual needs, preferences and locations.
                   </p>

                   <p>
                       The 'Postgrad mentoring' site is open to all postgraduate students at The University of Warwick but only registered members are able to actively engage in the platform.
                       Students can send a private question directly to a chosen Mentor, or add questions and answers to the 'Conversation' board which is visible, and can be responded to,
                       by all registered Warwick PG students.
                   </p>

                   <p>
                       Our team of PG Mentors have responsibility for making sure all questions get a response within a reasonable timeframe,
                       however, it is hoped that the open 'Conversation' section of the site will become a forum for PG Students to receive multiple responses from students
                       within the Warwick community – providing all users of the site with a number of perspectives and tips associated to the question.
                   </p>
                   <p>
                       In addition to this site, we plan to run termly face-to-face themed group mentoring sessions as topics become apparent –
                       our Mentors will lead on this aspect so that we are supporting the needs which have been highlighted by our students.
                   </p>

                   <p>
                       Further information on other initiatives organised to enhance our PG Community can be found on the
                       Postgraduates webpage: or you can <a href="mailto:pgcommunity@warwick.ac.uk">email</a> the team.
                   </p>
               </Col>
        </Row>;
};

export default Background;