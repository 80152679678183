import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import AsyncCreatableSelect from "react-select/async-creatable";
import { getAllData, postBodyReturnResponse, putBody } from "./shared/api-requests";
import { useHistory, useParams } from "react-router-dom";
import TextEditor from "./shared/text-editor";

const NewQuestion = () => {

    let pageTitle = "New question";

    const history = useHistory();
    const [questionId, setQuestionId] = useState();
    const [contentEditor, setContentEditor] = useState();
    const [anon, setAnon] = useState(false);
    const [title, setTitle] = useState("");
    const [tags, setTags] = useState([]);
    const [defaultTags, setDefaultTags] = useState([]);
    const [tagsLoaded, setTagsLoaded] = useState(false);

    // If this is not undefined then it's an edit
    const { id } = useParams();
    if (id !== undefined) {
        pageTitle = "Edit question";
    }

    const handleEditorChange = (content, editor) => {
        setContentEditor(content);
    }

    const loadQuestion = async () => {
        try {
            const data = await getAllData(`/api/questions/${id}`);
            setQuestionId(data.id);
            setTitle(data.title);
            setContentEditor(data.body);
            const selectValues = data.tags.map(t => { return { value: t, label: t } });
            setTags(selectValues);
            setDefaultTags(selectValues);
            setTagsLoaded(true);

        } catch (error) {
            console.log(error);
            //if (error.response.status === 401) {
            //    history.push("/login");
            //}
        }
    };

    useEffect(() => {
        // if this is an edit fetch the question
        if (id) {
            loadQuestion();
        } else {
            setTagsLoaded(true);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
       
        const payload = {
            title: title,
            body: contentEditor,
            anonymous: anon,
            tags: tags.map(v => v.value)
        };
        //Most common will be new questions
        if (id === undefined) {
            try {
                const response = await postBodyReturnResponse("/api/questions", payload);
                history.push(`/question/${response.data.questionId}`);
            } catch (error) {
                console.log(error);
            }
        } else {
            payload["id"] = questionId;
            try {
                const response = await putBody("/api/questions", payload);
                history.push(`/question/${response.data.questionId}`);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        if (name === "anon") {
            setAnon(value);
        }
        if (name === "title") {
            setTitle(value);
        }
    };

    const getTags = async (q) => {
        const minLength = 3;
        if (q.length < minLength) {
            return Promise.resolve([]);
        }

        try {
            const data = await getAllData(`/api/lookup?q=${q}`);
            return data;

        } catch (error) {
            console.log(error);
            //if (error.response.status === 401) {
            //    history.push("/login");
            //}
        }
        return Promise.resolve([]);
    };

    const handleTags = (values) => {
        setTags(values);
    };

    return <div className="full-height-reset">
        <Row>
            <Col md={12}>
                <h2>{pageTitle}</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control name="title" value={title} type="text" onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Label>Question</Form.Label>
                    
                    <TextEditor contentEditor={contentEditor} handleEditorChange={handleEditorChange} initialValue={null} height="500"/>

                    <Form.Label>Tags (optional)</Form.Label>
                    {tagsLoaded &&
                        <AsyncCreatableSelect
                            isMulti
                            cacheOptions
                            defaultOptions
                            name="tags"
                            defaultValue={defaultTags}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            loadOptions={getTags}
                            onChange={handleTags}
                        />
                    }

                    <Form.Check
                        type="checkbox"
                        id="anon"
                        name="anon"
                        value={anon}
                        onChange={handleInputChange}
                        label="Be anonymous"
                    />
                    <Button className="mt-2 mb-2" variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>

            </Col>
        </Row>
    </div>;
};

export default NewQuestion;