import React from "react";
import { isAdmin } from"./shared/utils";
import { Redirect, Route, useLocation } from "react-router-dom";

function AdminRoute({ component: Component, ...restOfProps }) {
    const location = useLocation();
    return (
        <Route
      {...restOfProps}
      render={(props) =>
          isAdmin() ? <Component {...props} /> : <Redirect to={`/login?returnUrl=${location.pathname}`} />
      }
    />
    );
}

export default AdminRoute;