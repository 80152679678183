import React from "react";
import Pagination from "react-bootstrap/Pagination";
import { useMediaQuery } from "react-responsive";

const PaginationBasic = (props) => {

    const items = [];
    const notMobile = useMediaQuery({ query: "(min-width: 768px)" });
    if (notMobile) {
        for (let number = 1; number <= props.pageCount; number++) {
            items.push(
                <Pagination.Item key={number} active={number === props.currentPage} value={number} onClick={props.changePage}>
                    {number}
                </Pagination.Item>,
            );
        }
    }

    return (
        <Pagination>
            <Pagination.First onClick={props.firstPage} disabled={props.currentPage <= 1} />
            <Pagination.Prev onClick={props.prevPage} disabled={props.currentPage <= 1} />
            {notMobile && items}
            <Pagination.Next onClick={props.nextPage} disabled={props.currentPage === props.pageCount} />
            <Pagination.Last onClick={props.lastPage} disabled={props.currentPage === props.pageCount} />
        </Pagination>
    );
};

export default PaginationBasic;



