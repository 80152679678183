import React from "react";
import Alert from "react-bootstrap/Alert";

const FailedLogin = (props) => {
    if (props.failed) {
        return <Alert variant="warning">{props.errorMessage}</Alert>;
    }
    return null;
};

export default FailedLogin;