import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import FailedLogin from "./failed-login";
import { decodeToken, getAuth } from "./shared/utils";
import { authenticationService } from "./shared/authentication-service";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const LoginForm = () => {
    const query = useQuery();
    const history = useHistory();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [failed, setFailed] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [show, setShow] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    const handleClose = () => {
        setShow(false);
        const returnUrl = query.get("returnUrl");
        if (returnUrl === null) {
            history.push("/");
        } else {
            history.push(returnUrl);
        }
    };

    const handleUserName = (event) => {
        setUserName(event.target.value);
    };

    const handlePassword = (event) => {
        setPassword(event.target.value);
    };

    useEffect(() => {
        setLoggedIn(getAuth());
    }, []);

    const handleSubmit = (event) => {
        setFailed(false);
        setErrorMessage("");
        const user = {
            userName: userName,
            password: password,
            rememberMe: true
        };

        axios.post("/api/token", user)
            .then(response => {
                authenticationService.setUser(response.data.token);
                const decoded = decodeToken(response.data.token);
                if (decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] === "FirstLogin") {
                    setShow(true);
                } else {
                    const returnUrl = query.get("returnUrl");
                    if (returnUrl === null) {
                        history.push("/");
                    } else {
                        history.push(returnUrl);
                    }
                }
            })
            .catch(
                (error) => {
                    setFailed(true);
                    console.log(error);
                    if (error.response.status === 401) {
                        const msg = "Please check your username or password";
                        setErrorMessage(msg);
                    }
                }
            );

        event.preventDefault();
    };

    return (
        <Row>
            <Col md={{ span: 4, offset: 4 }}>
                <div>
                    <h2>Log in</h2>
                    {loggedIn && <Alert variant="info">Already logged in</Alert>}
                    {!loggedIn && 
                    <form onSubmit={handleSubmit}>
                        <Form.Group controlId="userName">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                value={userName}
                                onChange={handleUserName}
                                placeholder="Username" />
                            <Form.Text className="text-muted">
                                Use your IT Services username and password
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={password}
                                onChange={handlePassword}
                                placeholder="Password" />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={userName === "" || password === ""}>Submit</Button>
                    </form>
                        }
                    <FailedLogin failed={failed} errorMessage={errorMessage} />
                </div>
            </Col>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>User profiles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        It appears that this is your first time here!
                        You can, optionally, create a user profile. To do this, use the menu beneath your name in the top right corner.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Continue to site</Button>
                </Modal.Footer>
            </Modal>
        </Row>
    );
};

export default LoginForm;