import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { TagCloud } from "react-tagcloud";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllData, getRss } from "./shared/api-requests";
import { DateFormat, TruncateBody, isAuthenticated  } from "./shared/utils";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";
import Nav from "react-bootstrap/Nav";
import MentorBlock from "./shared/mentor-block";
import RssBlock from "./shared/rss-block";
import TagBlock from "./shared/tag-block";
import PaginationBasic from "./shared/pagination-basic";
import AuthorLink from "./shared/author-link";
import { useMediaQuery } from "react-responsive";
//import { LinkContainer } from "react-router-bootstrap";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

let currentUrlParams = new URLSearchParams(window.location.search);

const Home = () => {
    const query = useQuery();
    const page = query.get("page") !== null ? parseInt(query.get("page"), 10) : 1;
    const tagParam = query.get("tag") !== null ? query.get("tag") : null;
    const category = query.get("cat") !== null ? query.get("cat") : "all";

    const history = useHistory();
    const [questions, setQuestions] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [mentors, setMentors] = useState([]);
    const [phdLifeResult, setPhdLifeResult] = useState();
    const [studyBlogResult, setStudyBlogResult] = useState();
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState(tagParam);
    const[questionCat, setQuestionCat] = useState(category);
    const [resultCount, setResultCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(page);
    const [mentions, setMentions] = useState(false);
    const notMobile = useMediaQuery({ query: "(min-width: 768px)" });

    const loadQuestions = async () => {

        try {
            let url = `/api/filteredQuestions?cat=${questionCat}&page=${currentPage}&pageSize=${pageSize}`;

            if (selectedTag!== undefined && selectedTag !== null) {
                url += `&tag=${selectedTag}`;
            }
            const data = await getAllData(url);
            setQuestions(data.questions);
            setResultCount(data.resultCount);
            setPageCount(Math.ceil(data.resultCount / pageSize));

        } catch (error) {
            console.log(error);
        }
    };
    
    const loadMentors = async () => {
        try {
            const data = await getAllData("/api/mentors");
            setMentors(data);
        } catch (e) {
            console.log(e);
        }
    };

    const loadTags = async () => {
        try {
            const data = await getAllData("/api/tagCloud");
            setTags(data);
        } catch (e) {
            console.log(e);
        }
    };

    const loadMentions = async () => {
        try {
            const data = await getAllData("/api/mentions");
            setMentions(data);
        } catch (e) {
            console.log(e);
        }
    };

    const phdLifeFeed = async () => {
        try {
            const phdLifeUrl = "https://phdlife.warwick.ac.uk/feed/";
            const response = await getRss(`/api/phdLife?endPoint=${phdLifeUrl}`);
            setPhdLifeResult(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const studyBlogFeed = async () => {
        try {
            const url = "https://studyblog.warwick.ac.uk/feed/";
            const response = await getRss(`/api/phdLife?endPoint=${url}`);
            setStudyBlogResult(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const loggedIn = isAuthenticated();
        //console.log(loggedIn);
        if (notMobile) {
            loadMentors();
            phdLifeFeed();
            studyBlogFeed();
            loadTags();
        }
       
        if (loggedIn) {
            loadMentions();
        }
      
        const qTag = query.get("tag");
        if (qTag !== undefined) {
            setSelectedTag(qTag);
        }
    }, []);

    useEffect(() => {
        if (selectedTag === null) {
            currentUrlParams.delete("tag");
            currentUrlParams.delete("page");
        } else {
            currentUrlParams.set("tag", selectedTag);
            currentUrlParams.set("page", currentPage);
        }
        
        if (currentPage > 1) {
            currentUrlParams.set("page", currentPage);
        }

        currentUrlParams.set("cat", questionCat);
        //currentUrlParams.set("page", currentPage);

        history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }, [selectedTag, currentPage, questionCat]);

    useEffect(() => {
        loadQuestions();
    }, [currentUrlParams.toString()]);

    const answersPlural = (answers) => {
        if (answers === 1) {
            return "answer";
        }
        return "answers";
    };

    const votesPlural = (answers) => {
        if (answers === 1) {
            return "vote";
        }
        return "votes";
    };

    const colourOptions = {
        luminosity: "dark"
    };

    const searchTags = (event) => {
        setCurrentPage(1);
        setSelectedTag(event.target.value);
    };

    const searchCloudTags = (tag) => {
        setCurrentPage(1);
        setSelectedTag(tag);
    };

    const clearTags = () => {
        setSelectedTag(null);
        setCurrentPage(1);
    }

    const toggleCat = () => {
        setCurrentPage(1);
        if (questionCat === "all") {
            setQuestionCat("unanswered");
        } else {
            setQuestionCat("all");
        }
    };

    const changePage = (event) => {
        setCurrentPage(parseInt(event.target.getAttribute("value"), 10));
    };

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    }
    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    }

    const firstPage = () => {
        setCurrentPage(1);
    };

    const lastPage = () => {
        setCurrentPage(pageCount);
    };

    return (
        <div>
            {mentions &&
                <Alert variant="info">You have new mentions. Visit your profile to see them</Alert>
                }
            {notMobile &&   <MentorBlock mentors={mentors} />}
          
            <Row style={{height:"100%", backgroundColor: "white"}}>
                {!notMobile && 
                     <Col sm={12} className="text-center">
                    <Button onClick={() => history.push("/new")} className="mb-1 mt-2">Ask a question</Button>
                    </Col>
                    }
                <Col md={9}>
                    <Nav fill variant="tabs" defaultActiveKey={questionCat} className="mt-3">
                        <Nav.Item>
                            
                            <Nav.Link eventKey="all" onClick={toggleCat}>All</Nav.Link>
                        </Nav.Item>
                       
                        <Nav.Item>
                                <Nav.Link eventKey="unanswered" onClick={toggleCat}>Unanswered</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <div className="mb-4">
                        {selectedTag !== undefined && selectedTag !== null &&
                            <Button size="sm"  className="mb-2">{selectedTag} <FontAwesomeIcon icon={["fa", "times"]} onClick={clearTags}/></Button>
                        }
                      
                        {questions.map(q => {
                            return <div key={q.id}>
                                <h4><Link to={`/question/${q.id}`}>{q.title}</Link></h4>
                                <div>{TruncateBody(q.body, 110)}</div>
                                <span>
                                    <Badge variant="secondary">{q.answers} {answersPlural(q.answers)}</Badge>{" "}
                                    <Badge variant="secondary">{q.votes} {votesPlural(q.votes)}</Badge></span>
                                <span className="small text-muted"> Added <DateFormat date={q.created} /> by{" "} 
                                    <AuthorLink author={q.author}/>
                                    </span>{" "}
                                {q.tags.length > 0 &&
                                        <TagBlock tags={q.tags} filter={searchTags}/>
                                }
                            </div>;
                        })}
                    </div>
                    <PaginationBasic 
                        pageCount={pageCount} 
                        currentPage={currentPage} 
                        changePage={changePage} 
                        nextPage={nextPage} 
                        prevPage={prevPage} 
                        firstPage={firstPage} 
                        lastPage={lastPage}/>
                </Col>
                {notMobile && 
                <Col md={3}>
                    <Button onClick={() => history.push("/new")} className="mb-2">Ask a question</Button>
                            <h6 style={{marginBottom:0}}>Popular tags</h6>
                            <TagCloud
                                minSize={14}
                                maxSize={38}
                                tags={tags}
                                colorOptions={colourOptions}
                                onClick={tag => searchCloudTags(tag.value)}
                            />
                    {phdLifeResult !== undefined && phdLifeResult !== null &&
                        <div>
                            <h6 className="mt-2">From Phd Life</h6>
                            <RssBlock items={phdLifeResult.channel.item} textLength={60} />
                        </div>
                    }
                    {studyBlogResult !== undefined && studyBlogResult !== null &&
                        <div>
                            <h6>From the Study Blog</h6>
                            <RssBlock items={studyBlogResult.channel.item} textLength={60} />
                        </div>
                    }
                </Col>
                }
            </Row>
        </div>
    );
};

export default Home;
