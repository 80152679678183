import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { getAllData } from "./shared/api-requests";
import { DateFormat } from "./shared/utils";
import PaginationBasic from "./shared/pagination-basic";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

let currentUrlParams = new URLSearchParams(window.location.search);

const SearchPage = () => {

    const query = useQuery();
    const history = useHistory();
    const q = query.get("q") !== undefined ? query.get("q") : "";
    const page = query.get("page") !== undefined ? parseInt(query.get("page"), 10) : 1;

    const [searchQuery, setSearchQuery] = useState(q);
    const [response, setResponse] = useState();
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(page);
   

    const changePage = (event) => {
        setCurrentPage(parseInt(event.target.getAttribute("value"), 10));
    };

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    }
    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    }

    const firstPage = () => {
        setCurrentPage(1);
    };

    const lastPage = () => {
        setCurrentPage(pageCount);
    };


    const handleQuery = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };


    const runSearch = async () => {
        if (searchQuery !== "" && currentPage !== 0 && searchQuery !== null) {
            const url = `/api/search?query=${searchQuery}&page=${currentPage - 1}`;
            currentUrlParams.set("page", currentPage);
            history.push(window.location.pathname + "?" + currentUrlParams.toString());
            try {
                const data = await getAllData(url);
                setResponse();
                setResponse(data);
                setPageCount(Math.ceil(data.count / 10));
            } catch (e) {
                console.log(e);
            }
        }
    };

    const submitSearch = (event) => {
        event.preventDefault();
        currentUrlParams.set("q", searchQuery);
        history.push(window.location.pathname + "?" + currentUrlParams.toString());
        runSearch();
    }

    useEffect(() => {
        currentUrlParams.set("q", searchQuery);
        history.push(window.location.pathname + "?" + currentUrlParams.toString());
        runSearch();
    }, []);

    useEffect(() => {
        runSearch();
    }, [currentPage]);

  
    const TitleLink = ({ result }) => {
        let href = `/question/${result.questionId}`;
        let text = result.questionTitle;
        if (result.answerId !== 0) {
            text = `Answer to: ${result.questionTitle}`;
            href += `#answer-${result.answerId}`;
        }
        return <Link to={href}>{text}</Link>;
    }

    const Author = ({ result }) => {

        let verb = "Asked";
        if (result.answerId !== 0) {
            verb = "Answered";
        }
        return <span>{verb} <DateFormat date={result.created} /> by {result.author}</span>;
    };

    const Editor = ({ result }) => {
        if (result.editor !== null) {
            return <span>, edited <DateFormat date={result.modified} /> by {result.editor}</span>;
        } else {
            return null;
        }
    };
    return <Row className="full-height-reset">

        <Col md={12}>
            <h2>Search</h2>
            <Form inline onSubmit={submitSearch}>
                <Form.Control type="text" placeholder="Search" className="mr-sm-2" value={searchQuery} onChange={handleQuery} />
                <Button variant="outline-success" type="submit">Search</Button>
            </Form>
            {response !== undefined &&
                <div>
                <p>{response.count} results</p>
                {response.results.length === 0 && <Alert variant="info" className="mt-4">No results found</Alert>}
                
                    {response.results.map((r, idx) => {
                        return <div key={idx}>
                            <h3><TitleLink result={r}/></h3>
                            <div>{r.snippets.body.map((s, index) => { return <div key={index} dangerouslySetInnerHTML={{ __html: s }}></div> })}</div>
                            <p className="text-muted" style={{ fontSize: "0.9em" }}> <Author result={r} /><Editor result={r} /></p>
                        </div>;
                    })}</div>
            }

            <PaginationBasic 
                pageCount={pageCount} 
                currentPage={currentPage} 
                changePage={changePage} 
                nextPage={nextPage} 
                prevPage={prevPage} 
                firstPage={firstPage} 
                lastPage={lastPage}/>
        </Col>
    </Row>;

};

export default SearchPage;