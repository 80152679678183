import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getAllData, deleteById } from "../shared/api-requests";
import { DateFormat } from "../shared/utils";
import { HashLink } from 'react-router-hash-link';

const FlagTable = (props) => {
    return <Table>
        <thead>
            <tr>
                <th>Flag type</th>
                <th>Term</th>

                <th>Date notified</th>
                <th>Question</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {props.data.map(f => {
                return <tr key={f.id}><td>{f.flagType}</td><td>{f.term}</td><td><DateFormat date={f.dateNotified} /> </td><td>
                    <HashLink smooth to={`/question/${f.questionId}#${props.itemType}-${f.itemId}`}>{f.questionTitle}</HashLink>
                </td><td><Button variant="light" size="sm" value={f.id} onClick={props.handleShow}>Clear</Button></td></tr>;
            })}
        </tbody>
    </Table>;
};

const FlaggedItems = () => {

    const [data, setData] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setDeleteId(0);
        setShow(false);
    }
    
    const handleShow = (event) => {
        setShow(true);
        setDeleteId(event.target.value);
    };
    const [deleteId, setDeleteId] = useState(0);

    const loadFlagged = async () => {
        try {
            const dataResponse = await getAllData("/api/flagged");
            setData(dataResponse);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteFlag = async () => {
        setShow(false);
        try {
            await deleteById("/api/flagged", parseInt(deleteId, 10));
            setDeleteId(0);
            loadFlagged();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadFlagged();
    }, []);

    return <div className="full-height-reset">
        <Row>
            <Col md={{ span: 8, offset: 2 }}>
                <h2>Flagged items</h2>
                {data !== null & data !== undefined &&
                    <div>
                        <h4>Questions</h4>
                        <FlagTable data={data.questions} itemType="question" handleShow={handleShow} />
                        <h4>Question comments</h4>
                        <FlagTable data={data.questionComments} itemType="questioncomment" handleShow={handleShow} />
                        <h4>Answers</h4>
                        <FlagTable data={data.answers} itemType="answer" handleShow={handleShow} />
                        <h4>Answer comments</h4>
                        <FlagTable data={data.answerComments} itemType="answercomment" handleShow={handleShow} />
                    </div>
                }

            </Col>
        </Row>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Clear flag</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to clear this flag?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={deleteFlag}>
                    Remove
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;

};

export default FlaggedItems;