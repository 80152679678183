import React from "react";
import Button from "react-bootstrap/Button";

const TagBlock = (props) => {
    return <span>{
        props.tags.map(k => {
            return <Button key={k} className="ml-1" size="sm" variant="light" value={k} onClick={props.filter}>
                {k}
            </Button>;
        })}</span>;
};

export default TagBlock;