import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useParams, useHistory, Link } from "react-router-dom";
import { getAllData, deleteById } from "../shared/api-requests";
import MentorProfileBlock from "../shared/mentor-profile-block";

const MentorDelete = () => {
    const { id } = useParams();
    const history = useHistory();
    const [profile, setProfile] = useState();
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const loadProfile = async () => {
        try {
            const data = await getAllData(`/api/mentorDelete?id=${id}`);
            setProfile(data);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadProfile();
    }, []);

    const handleDelete = async () => {
        try {
            setError(false);
            await deleteById("/api/mentorDelete", id);
            history.push("/admin/mentors");
        } catch (e) {
            console.log(e);
            if (e.response.status === 500) {
                setErrorMessage(
                    "We can't delete all of this mentor's content. " +
                    "Please try to delete, questions, answers and comments individually and try again. " +
                    "If that doesn't work get in touch with the system admin and they will do it for you");
                setError(true);
            } else {
                setErrorMessage("We have experienced an error. Please contact the system admin to report this.");
                setError(true);
            }
        }
    };

    const handleCancel = () => {
        history.push("/admin/mentors");
    };
   
    const deleteActions = () => {
        return <Row>
            <Col md={{ span: 11, offset: 1 }}>
                <Button variant="danger" onClick={handleDelete}>Delete</Button>{" "}
                <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
            </Col>
        </Row>;
    };

    return <div>
        {error && <Alert variant="warning">{errorMessage}</Alert>}
        {profile !== undefined &&
            <div>
                {deleteActions()}
                <MentorProfileBlock profile={profile} />
            </div>
        }

    </div>;
};

export default MentorDelete;