import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { getAllData, postBodyReturnResponse, deleteById, putBody } from "./shared/api-requests";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentBlock from "./shared/comment-block";
import TagBlock from "./shared/tag-block";
import { DateFormat, userInfo } from "./shared/utils";
import TextEditor from "./shared/text-editor";
import AuthorLink from "./shared/author-link";

const QuestionView = () => {
    const { id } = useParams();
    const history = useHistory();
    const [question, setQuestion] = useState(null);
    const [anonymousAnswer, setAnonymousAnswer] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteAnswerModal, setShowDeleteAnswerModal] = useState(false);
    const [questionVotes, setQuestionVotes] = useState(0);
    const [answerVotes, setAnswerVotes] = useState({});
    const [answerEditId, setAnswerEditId] = useState(0);
    const [deleteAnswerId, setDeleteAnswerId] = useState();
    const [answerEditContent, setAnswerEditContent] = useState();
    const [answerContent, setAnswerContent] = useState();
    const [currentUser, setCurrentUser] = useState(null);

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const loadQuestion = async () => {
        try {
            const data = await getAllData(`/api/questions/${id}`);
            setQuestion(data);
            setQuestionVotes(data.votes);
            const aVotes = data.answers.reduce((a, x) => ({ ...a, [x.id]: x.votes }), {});
            setAnswerVotes(aVotes);

        } catch (error) {
            if (error.response.status === 401) {
                history.push(`/login?returnUrl=/question/${id}`);
            } else {
                console.log(error);
            }
        }
    };

    const addComment = async (comment, anon, apiUrl, parentId) => {
        if (comment === undefined) {
            return;
        }

        var payload = {
            comment: comment,
            parentId: parentId,
            anonymous: anon
        };

        try {
            await postBodyReturnResponse(apiUrl, payload);
            loadQuestion();

        } catch (e) {
            console.log(e);
        }
    };

    const editComment = async (comment, commentId, apiUrl, parentId) => {
        if (comment === undefined) {
            return;
        }

        var payload = {
            comment: comment,
            commentId: commentId,
            parentId: parentId
        };

        try {
            await putBody(apiUrl, payload);
            loadQuestion();

        } catch (e) {
            console.log(e);
        }
    };

    const deleteComment = async (commentId, apiUrl) => {
        try {
            await deleteById(apiUrl, commentId);
            loadQuestion();
        } catch (e) {
            console.log(e);
        }
    };

    const confirmDelete = () => {
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        try {
            await deleteById("/api/questions", id);
            setShowDeleteModal(false);
            history.push("/");
        } catch (e) {
            console.log(e);
        }
    };

    const submitAnswer = async () => {
        try {
            const payload = {
                yourAnswer: answerContent,
                questionId: question.id,
                answerAnonymous: anonymousAnswer
            };
            await postBodyReturnResponse("/api/answers", payload);
            setAnswerContent("");
            setAnonymousAnswer(false);
            loadQuestion();

        } catch (error) {
            console.log(error);
        }
    };

    const handleAnonAnswer = (event) => {
        setAnonymousAnswer(event.target.checked);
    }

    const openEditAnswer = (event) => {
        setAnswerEditId(parseInt(event.target.value, 10));

    }
    const editAnswer = async () => {
        try {
            const payload = {
                answerId: answerEditId,
                body: answerEditContent
            };
            const response = await putBody("/api/answers", payload);
            const newQuestion = { ...question };
            const replaceIndex = newQuestion.answers.findIndex(a => a.id === response.data.id);
            newQuestion.answers[replaceIndex] = response.data;
            setQuestion(newQuestion);
            setAnswerEditId(0);
            setAnswerEditContent("");
        } catch (e) {
            console.log(e);
        }
    }

    const closeEditAnswer = () => {
        setAnswerEditId(0);
    }

    const confirmDeleteAnswer = (event) => {
        setShowDeleteAnswerModal(true);
        setDeleteAnswerId(parseInt(event.target.value, 10));
    };

    const deleteAnswer = async () => {
        try {
            await deleteById("/api/answers", deleteAnswerId);
            setDeleteAnswerId(0);
            setShowDeleteAnswerModal(false);
            loadQuestion();
        } catch (e) {
            console.log(e);
        }
    };

    const handleCloseDeleteAnswerModal = () => {
        setShowDeleteAnswerModal(false);
        setDeleteAnswerId(0);
    };

    const voteQuestion = async () => {
        try {
            const payload = { questionId: id };
            const response = await postBodyReturnResponse("/api/questionVote", payload);
            setQuestionVotes(response.data);
        } catch (e) {
            console.log(e);
        }
    };

    const voteAnswer = async (event) => {
        const answerId = parseInt(event.target.value, 10);
        const payload = { answerId: answerId };
        try {
            const response = await postBodyReturnResponse("/api/answerVote", payload);
            setAnswerVotes(prevState => ({ ...prevState, [response.data.answerId]: response.data.score }));

        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        loadQuestion();
        setCurrentUser(userInfo());
    }, []);

    const searchTags = (event) => {
        history.push(`/?cat=all&tag=${event.target.value}&page=1`);
    };
    return <div className="full-height-reset">{question !== null && <Row id={`question-${question.id}`}>
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete question</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this question?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDeleteModal}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showDeleteAnswerModal} onHide={handleCloseDeleteAnswerModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete answer</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this answer?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDeleteAnswerModal}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={deleteAnswer}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
        <Col md={2} className="mt-3">
            <Button role="button" onClick={voteQuestion}><FontAwesomeIcon icon={["fa", "thumbs-up"]} /> Vote{" "}
                <Badge variant="light">{questionVotes}</Badge>
            </Button>
        </Col>
        <Col md={8}>
            <h2>{question.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: question.body }}></p>
            <TagBlock tags={question.tags} filter={searchTags} />
            <p className="text-muted" style={{ fontSize: "0.9em" }}>Asked <DateFormat date={question.created} /> by <AuthorLink author={question.author} />
                {question.editor !== null && <span>, edited <DateFormat date={question.modified} /> by <AuthorLink author={question.editor} /></span>}
            </p>
            {question.canEdit &&
                <p><Link to={`/edit/${id}`}>Edit</Link>{" "}<Button variant="danger" onClick={confirmDelete}>Delete</Button></p>
            }

            <CommentBlock
                title="Question"
                comments={question.comments}
                add={addComment}
                edit={editComment}
                deleteComment={deleteComment}
                apiUrl={"/api/questionComment"}
                parentId={id}
                currentUser={currentUser}
            />
        </Col>
    </Row>
    }
        {question !== null && question.answers.length > 0 && <div>
            <Row>
                <Col md={{ span: 8, offset: 2 }}>
                    <h3 className="mt-4">Answers</h3>
                    <hr />
                </Col>
            </Row>
            {question.answers.map(a => {
                return <Row key={a.id} >
                    <Col md={2} className="mt-2 mb-2">
                        <Button role="button" value={a.id} onClick={voteAnswer}><FontAwesomeIcon icon={["fa", "thumbs-up"]} /> Vote{" "}
                            <Badge variant="light">{answerVotes[a.id]}</Badge>
                        </Button>
                    </Col>
                    <Col md={8}>
                        {answerEditId !== a.id && <div id={`answer-${a.id}`}>
                            <p dangerouslySetInnerHTML={{ __html: a.body }}></p>
                            <p className="text-muted" style={{ fontSize: "0.9em" }}>Answered <DateFormat date={a.created} /> by <AuthorLink author={a.author} />
                                {a.modified !== null && <span>, edited <DateFormat date={a.modified} /> by <AuthorLink author={a.editor} /></span>}
                            </p>
                            {a.canEdit &&
                                <p>
                                    <Button variant="secondary" value={a.id} onClick={openEditAnswer}>Edit</Button>{" "}
                                    <Button variant="danger" value={a.id} onClick={confirmDeleteAnswer}>Delete</Button>
                                </p>
                            }
                        </div>
                        }
                        {answerEditId === a.id && <div>
                            <TextEditor
                                contentEditor={answerEditContent !== null ? answerEditContent : a.body}
                                handleEditorChange={setAnswerEditContent}
                                initialValue={a.body}
                                height="500"
                            />
                            <p className="mt-2"><Button size="sm" value={a.id} onClick={editAnswer} disabled={answerEditContent === undefined}>Submit</Button>{" "}
                                <Button size="sm" variant="secondary" value={0} onClick={closeEditAnswer}>Cancel</Button>
                            </p>
                        </div>}
                        <CommentBlock
                            title="Answer"
                            comments={a.comments}
                            add={addComment}
                            edit={editComment}
                            deleteComment={deleteComment}
                            apiUrl={"/api/answerComment"}
                            parentId={a.id}
                            currentUser={currentUser}
                        />
                        <hr />
                    </Col>
                </Row>;
            })
            }
        </div>
        }
        {question !== null &&
            <Row>
                <Col md={{ span: 8, offset: 2 }}>
                    <h3>Your answer</h3>
                    <TextEditor
                        contentEditor={answerContent}
                        handleEditorChange={setAnswerContent}
                        initialValue={null}
                        height="500"
                    />
                    <Form.Check
                        type="checkbox"
                        label="Be anonymous"
                        id="anon-answer-check"
                        checked={anonymousAnswer}
                        onChange={handleAnonAnswer}
                    />
                    <Button className="mt-2 mb-2" onClick={submitAnswer}>Submit</Button>
                </Col>
            </Row>
        }

    </div>;
};

export default QuestionView;

