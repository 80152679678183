import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import countries from "countries-list";
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import { Editor } from "@tinymce/tinymce-react";

const ProfileForm = ({ userType, profile, handleSubmit, setProfile, setContentEditor, contentEditor, onPhotoChange, fileError, errorMessage }) => {
    const defaultCountry = "---Select a country---";
    const [countryNames, setCountryNames] = useState([]);
    const [initialContent, setInitialContent] = useState();
    const history = useHistory();


    useEffect(() => {
        const countryCodes = Object.keys(countries.countries);
        const countryList = countryCodes.map(code => countries.countries[code].name);
        setCountryNames(countryList.sort());
    }, []);

    const handleEditorChange = (content, editor) => {
        setContentEditor(content);
    }

    const handleFieldChange = (event) => {
        const editedProfile = { ...profile, [event.target.name]: event.target.value };
        setProfile(editedProfile);
    };

    const handleCheckbox = () => {
        if (!profile.notActive) {
            const notActive = { ...profile, notActive: true, notActiveReason: "Has left the university" };
            setProfile(notActive);
        } else {
            const active = { ...profile, notActive: false, notActiveReason: "" };
            setProfile(active);
        }
    };

    const handleNotifyMe = () => {
        const editedProfile = { ...profile, notifyMe: !profile.notifyMe };
        setProfile(editedProfile);
    };


    return <div className="full-height-reset"><Row>
        <Col md={{ span: 6, offset: 3 }}>
            <h2>Edit {userType} profile</h2>
            <h4>{profile.fullName}</h4>
            {profile.notActive &&
                <Alert variant="info">{profile.notActiveReason}</Alert>
            }
            <Form onSubmit={handleSubmit} encType="multipart/form-data" className="mb-2">
                <Form.Group controlId="course">
                    <Form.Label>Course, department</Form.Label>
                    <Form.Control type="text" value={profile.courseDepartment || ""} name="courseDepartment" onChange={handleFieldChange} />
                </Form.Group>

                <Form.Group controlId="yearMode">
                    <Form.Label>Year and mode of study</Form.Label>
                    <Form.Control type="text" name="yearMode" value={profile.yearMode || ""} onChange={
                        handleFieldChange} />
                </Form.Group>
                <Form.Group controlId="country">
                    <Form.Label>Home country</Form.Label>
                    <Form.Control as="select" value={profile.homeCountry || ""} name="homeCountry" onChange={
                        handleFieldChange}>
                        <option>{defaultCountry}</option>
                        {countryNames.map(c => {
                            return <option key={c} value={c}>{c}</option>;
                        })}
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="preferredEmail">
                    <Form.Label>Preferred email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={profile.email || ""} name="email" onChange={handleFieldChange} />
                    <Form.Text className="text-muted">
                        Email address can be changed from the university one if preferred.
                    </Form.Text>
                </Form.Group>
                {userType !== "mentor" &&
                    <Form.Check
                        type="checkbox"
                        checked={profile.notifyMe}
                        name="notifyMe"
                        label="Receive email notifications"
                        onChange={handleNotifyMe}
                    />
                }

                {userType === "mentor" &&
                    <div>
                        <Form.Group controlId="preferredName">
                            <Form.Label>Preferred first name</Form.Label>
                            <Form.Control
                                type="text"
                                value={profile.preferredFirstName}
                                name="preferredFirstName"
                                onChange={handleFieldChange} />
                            <Form.Text className="text-muted">This is how the given name is displayed on the home page</Form.Text>
                        </Form.Group>
                        <Form.Label>Biography</Form.Label>
                        <Editor
                            initialValue={initialContent}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: "link",
                                toolbar: "undo redo | formatselect | " +
                                    "bold italic | " +
                                    "bullist numlist outdent indent | link | " +
                                    "removeformat",
                                content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }"
                            }}
                            value={contentEditor}
                            onEditorChange={handleEditorChange}
                        />
                    </div>
                }
                {profile.imageName &&
                    <div>
                        <p>Current photo</p>
                        <Image src={`/images/${profile.imageName}?w=200`} />
                    </div>
                }
                <Form.Group>
                    <Form.File id="photo" label="Add or change photo" onChange={onPhotoChange} />
                </Form.Group>
                {userType === "mentor" &&
                    <div>
                        <Form.Check
                            type="checkbox"
                            checked={profile.notActive}
                            name="notActive"
                            label="Not active"
                            onChange={handleCheckbox}
                        />
                        {profile.notActive &&
                            <Form.Group controlId="notActiveReason">
                                <Form.Label>Not active reason</Form.Label>
                                <Form.Control type="text" value={profile.notActiveReason} name="notActiveReason" onChange={
                                        handleFieldChange} />
                                <Form.Text className="text-muted">
                                    Change the default reason if required.
                                </Form.Text>
                            </Form.Group>
                        }
                    </div>
                }
                <Button variant="primary" type="submit">Submit</Button>{" "}
                <Button variant="secondary" type="button" onClick={history.goBack}>Cancel</Button>
            </Form>
            {fileError && <Alert variant="warning">{errorMessage}</Alert>}
        </Col>
    </Row></div>;
};

export default ProfileForm;