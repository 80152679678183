import React from "react";
import Container  from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavMenu from "./NavMenu";

const Layout = (props) => {
    return (
        <Container style={{backgroundColor: "white", height: "100vh"}} >
            <NavMenu />
                <Row>
                    <Col md={12}>
                        <div className="banner-wrap">
                            <div className="banner-content">
                                <h1 className="display-4 text-center site-title">Ask a postgrad</h1>
                            </div>
                        </div>
                        </Col>
                    </Row>

          
          {props.children}
        </Container>
    );
};

export default Layout;