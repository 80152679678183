import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const UserGuide = () => {
    return <Row>
        <Col md={{span:6, offset: 3}}>
            <h2>User guide</h2>
            <ul>
                <li>Make sure that you post actual questions. That is, something that can be answered.</li>
                <li>If you want to, you can post items anonymously.</li>
                <li>Make sure that answers address the question.</li>
                <li>Even if a question already has an answer, multiple perspectives can be good.</li>
                <li>Stay on topic.</li>
                <li>You can use comments to support, add to, or qualify a question or answer.</li>
                <li>Vote up answers and questions that you think are important, interesting, or insightful. This way everyone sees what the community values.</li>
                <li>Remember that for some of our users English is a second language.</li>
            </ul>
            </Col>
        </Row>;
};

export default UserGuide;