import React from "react";
import { Route } from "react-router";
import Layout from "./components/Layout";
import Home from "./components/Home";
import QuestionView from "./components/question-view";
import NewQuestion from "./components/new-question";
import MentorProfile from "./components/mentor-profile";
import UserProfile from "./components/user-profile";
import UserGuide from "./components/about/user-guide";
import Conditions from "./components/about/conditions";
import Background from "./components/about/background";
import Contact from "./components/about/contact";
import LoginForm from "./components/login-form";
import UserEdit from "./components/user-edit";
import Mentors from "./components/admin/mentors";
import MentorDelete from "./components/admin/mentor-delete";
import MentorCreate from "./components/admin/mentor-create";
import WarningSigns from "./components/admin/warning-signs";
import FlaggedItems from "./components/admin/flagged-items";
import Admins from "./components/admin/admins";
import ProtectedRoute from "./components/protected-route";
import AdminRoute from "./components/admin-route";
import SearchPage from "./components/search";
import ManageUsers from "./components/admin/users";
import ManageTags from "./components/admin/tags";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare, faCoffee, faThumbsUp, faTimes } from "@fortawesome/free-solid-svg-icons";

library.add(faCheckSquare, faCoffee, faThumbsUp, faTimes);

const App = () => {
    const displayName = App.name;
    return (
        <Layout>
            <Route path="/login" component={LoginForm} />
            <Route exact path="/" component={Home} />
            <Route path="/filter/:tag" component={Home} />
            <ProtectedRoute path="/question/:id" component={QuestionView} />
            <ProtectedRoute path="/new" component={NewQuestion} />
            <ProtectedRoute path="/edit/:id" component={NewQuestion} />
            <ProtectedRoute exact path="/mentor/:id" component={MentorProfile} />
            <ProtectedRoute path="/search" component={SearchPage} />
            <ProtectedRoute exact path="/user/:id" component={UserProfile} />
            <ProtectedRoute exact path="/user/edit/:id" component={UserEdit} />

            <AdminRoute path="/admin/mentors" component={Mentors} />
            <AdminRoute path="/admin/warning-signs" component={WarningSigns} />
            <AdminRoute path="/admin/flagged" component={FlaggedItems} />
            <AdminRoute path="/admin/manage-admins" component={Admins} />
            <AdminRoute path="/admin/users" component={ManageUsers} />
            <AdminRoute path="/admin/tags" component={ManageTags} />
            <ProtectedRoute path="/profile/:userType/edit/:id" component={UserEdit} />
            <AdminRoute path="/profile/mentor/delete/:id" component={MentorDelete} />
            <AdminRoute path="/profile/mentor/create" component={MentorCreate} />

            <Route path="/about/userguide" component={UserGuide} />
            <Route path="/about/conditions" component={Conditions} />
            <Route path="/about/background" component={Background} />
            <Route path="/about/contact" component={Contact} />
        </Layout>
    );
};

export default App;
