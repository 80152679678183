import React from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import { DateFormat } from "./utils";

const firstName = (fullName) => {
    if (fullName !== null && fullName !== undefined) {
        return fullName.split(" ")[0];
    }
    return "NA";
};

const LastLogin = ({date}) => {
    if (date !== null) {
        return <span className="small text-muted" style={{ paddingLeft: "10px" }}>Logged in  <DateFormat date={date
} /></span>;
    } else {
        return <span className="small text-muted" style={{ paddingLeft: "10px" }}>Never logged in</span>;
    }

};

const MentorBlock = (props) => {
    return <Row style={{ marginTop: "10px", backgroundColor:"white" }}>
        {props.mentors.map(m => {
            return <Col md={3} key={m.id}><Card style={{ marginBottom: 10 }} >
                <div>
                    <div className="mentor-pic">
                        <Image src={`/images/${m.imageName}?w=90&h=90&mode=crop`} roundedCircle style={{ width: "100%" }} />
                    </div>
                    <div className="mentor-text">
                        <Link to={`/mentor/${m.id}`}>
                        <h6 style={{ marginBottom: 0, marginTop: 0 }}>{m.preferredFirstName !== null ? m.preferredFirstName : firstName(m.fullName)}</h6>
                            </Link>
                        {m.courseDepartment}
                    </div>
                </div>
                <LastLogin date={m.lastLogIn}/>
            </Card>
            </Col>;
        })}
    </Row>;
};

export default MentorBlock;