import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { userInfo, removeToken } from "./shared/utils";

const NavMenu = () => {
    const history = useHistory();
    const location = useLocation();
    const userDetails = userInfo();
    const [searchQuery, setSearchQuery] = useState("");

    const logOut = () => {
        removeToken();
        history.push("/");
    }

    const submitSearch = (event) => {
        event.preventDefault();
        history.push(`/search?q=${searchQuery}&page=1`);
        setSearchQuery("");
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const UserNav = () => {
        if (userDetails !== null && userDetails !== undefined) {
            return <Nav>
                <NavDropdown title={userDetails.fullName} id="user-log">
                    <NavDropdown.Item onClick={logOut}>Log out</NavDropdown.Item>
                    <LinkContainer to={`/user/${userDetails.sub}`}>
                        <NavDropdown.Item>My profile</NavDropdown.Item>
                    </LinkContainer>
                </NavDropdown>
            </Nav>;
        } else {
            return <Nav.Item>
                <LinkContainer to="/login">
                    <Nav.Link>Log in</Nav.Link>
                </LinkContainer>
            </Nav.Item>;
        }
    };

    return <Navbar bg="light" expand="lg" style={{ marginRight: "-15px", marginLeft: "-15px" }}>
        <LinkContainer to="/">
            <Navbar.Brand href="#home"><img className="svg-logo" src="logo.svg" alt="Warwick logo" /></Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <LinkContainer to="/">
                    <Nav.Link href="#">Home</Nav.Link>
                </LinkContainer>
                <NavDropdown title="About" id="basic-nav-dropdown">
                    <LinkContainer to="/about/userguide">
                        <NavDropdown.Item>User guide</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/about/conditions">
                        <NavDropdown.Item>Conditions of use</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/about/background">
                        <NavDropdown.Item>Background</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/about/contact">
                        <NavDropdown.Item>Contact</NavDropdown.Item>
                    </LinkContainer>
                </NavDropdown>

                {userDetails !== null && userDetails !== undefined && userDetails.admin
                    &&
                    <NavDropdown title="Admin" id="admin-nav-dropdown">
                      {/*  <NavDropdown.Item href="#action/3.1">Answers by mentors</NavDropdown.Item>*/}
                        <LinkContainer to="/admin/mentors">
                            <NavDropdown.Item>Mentors</NavDropdown.Item>
                        </LinkContainer>
                      <LinkContainer to="/admin/users">
                          <NavDropdown.Item>Users</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/admin/tags">
                          <NavDropdown.Item>Tags</NavDropdown.Item>
                      </LinkContainer>
                        <LinkContainer to="/admin/flagged">
                            <NavDropdown.Item>Flagged items</NavDropdown.Item>
                        </LinkContainer>
                        <NavDropdown.Divider />
                        <LinkContainer to="/admin/warning-signs">
                            <NavDropdown.Item>Warning signs</NavDropdown.Item>
                        </LinkContainer>
                      <LinkContainer to="/admin/manage-admins">
                          <NavDropdown.Item>Assign admins</NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                }
            </Nav>
            {location.pathname !== "/search" &&
                <Form inline action="/search" onSubmit={submitSearch}>
                    <Form.Control type="text" placeholder="Search" className="mr-sm-2" name="q" value={searchQuery} onChange={handleSearch} />
                    <Button variant="outline-success" type="submit" disabled={searchQuery === ""}>Search</Button>
                </Form>
            }

            <UserNav />
        </Navbar.Collapse>
    </Navbar>;
};

export default NavMenu;