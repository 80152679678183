import { authHeader } from "./utils";

const axios = require("axios").default;

export const questionSummaryUrl = "/api/questions";

async function getAllData(url) {
    const response = await axios.get(url, { headers: authHeader() });
    return response.data;
}

async function getAll(url) {
    const response = await axios.get(url, { headers: authHeader() });
    return response;
}

async function getRss(url) {
    const response = await axios.get(url);
    return response;
}

async function postBodyReturnResponse(url, body) {
    const response = await axios.post(url, body, { headers: authHeader() } );
    return response;
}

async function postNoBody(url) {
    const response = await axios.post(url, null, { headers: authHeader() } );
    return response;
}

async function putBody(url, body) {
    const response = await axios.put(url, body,  { headers: authHeader() });
    return response;
}

async function deleteById(url, id) {
    const response = await axios.delete(`${url}/${id}`,  { headers: authHeader() });
    return response;
}


export { 
    getAllData,
    postBodyReturnResponse,
    putBody,
    deleteById,
    getRss,
    getAll,
    postNoBody
    //getSingleTask, 
    //updateTask, postBody, 
    //deleteById, getWithParams, 
   
    //getResponse, 
    //postBodyNoAuth
    };