import React, { useState, useEffect } from "react";
import {useHistory } from "react-router-dom";
import { getAllData } from "./shared/api-requests";
import { userInfo } from "./shared/utils";
import axios from "axios";
import ProfileForm from "./shared/profile-form";
import { buildForm, defaultCountry } from "./shared/form-data";

const UserEdit = (props) => {
    const { params } = props.match;
    const history = useHistory();
    const [profile, setProfile] = useState();
    const [photo, setPhoto] = useState();
    const [fileError, setFileError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const defaultCountry = "---Select a country---";
    const [contentEditor, setContentEditor] = useState();
  
    const firstName = (fullName) => {
        return fullName.split(" ")[0];
    };

    const loadProfile = async () => {
        try {
            const data = await getAllData(`/api/${params.userType}?id=${params.id}`);
            const loggedInUser = userInfo();
            if (loggedInUser.sub !== data.userName && !loggedInUser.admin) {
                history.push("/login");
            }

            // prevent mentors from editing their own profiles
            if (!loggedInUser.admin && params.userType === "mentor") {
                history.push("/login");
            }
            if (data.preferredFirstName !== undefined && data.preferredFirstName === null) {
                data.preferredFirstName = firstName(data.fullName);
            }

            if (data.bio !== undefined) { 
                setContentEditor(data.bio);
            }
            setProfile(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadProfile();
    }, []);

    const onPhotoChange = event => {
        setFileError(false);
        setErrorMessage("");
        setPhoto(event.target.files[0]);
    };
    const validImages = ["image/jpg", "image/jpeg", "image/png"];
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData =buildForm(profile, contentEditor);
        if (photo !== undefined) {
            if (!validImages.includes(photo.type)) {
                setFileError(true);
                setErrorMessage("Not a valid image file");
                return;
            }
            formData.append("File", photo);
        }

        const token = localStorage.getItem("ask_token");
        axios.put(`/api/${params.userType}Profile`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': `Bearer ${token}`
                }
            }).then(function (response) {
                history.push(`/${params.userType}/${params.id}`);
            })
            .catch(function (error) {
                setFileError(true);
                setErrorMessage(error.response.data.message);
                console.log(error);
            });
    };

    return <div>
        {profile !== null && profile !== undefined &&
           <ProfileForm 
            userType={params.userType} 
            profile={profile} 
            handleSubmit={handleSubmit}
            setProfile={setProfile}
            setContentEditor={setContentEditor}
            contentEditor={contentEditor}
            onPhotoChange={onPhotoChange}
            fileError={fileError}
            errorMessage={errorMessage}
            />
        }
    </div>;
}

export default UserEdit;