import React from "react";
import { authenticationService } from './shared/authentication-service';
import { Redirect, Route, useLocation } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
    const location = useLocation();
    //const currentUser = authenticationService.currentUserValue;
    //console.log(currentUser);

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                authenticationService.currentUserValue ? <Component {...props} /> : <Redirect to={`/login?returnUrl=${location.pathname}`} />
            }
        />
    );
}

export default ProtectedRoute;