import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { getAllData, deleteById } from "./shared/api-requests";
import { DateFormat } from "./shared/utils";
import MentionBlock from "./shared/mention-block";
import { userInfo , removeToken } from "./shared/utils";


const UserProfile = () => {
    const { id } = useParams();
    const history = useHistory();
    const [profile, setProfile] = useState();
    const [cat, setCat] = useState("questions");
    const[error, setError] = useState(false);
    const[errorMessage, setErrorMessage]=useState("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setError(false);
        setErrorMessage("");
        setShow(true);
    }
    const [currentUser, setCurrentUser] = useState(null);

    const loadProfile = async () => {
        try {
            const data = await getAllData(`/api/userProfile/${id}`);
            setProfile(data);

        } catch (error) {
            console.log(error);
            //if (error.response.status === 401) {
            //    history.push("/login");
            //}
        }
    };

    useEffect(() => {
        loadProfile();
        setCurrentUser(userInfo());
    }, []);

    const toggleCat = () => {
        if (cat === "questions") {
            setCat("answers");
        } else {
            setCat("questions");
        }
    };
    const activity = () => {
        let questionContent = "";
        let answerContent = "";
        const questionCount = profile.questions.length;
        const answerCount = profile.answers.length;

        if (questionCount === 1) {
            questionContent = "1 question";
        }
        if (questionCount > 1) {
            questionContent = questionCount + " questions";
        }

        if (answerCount === 1) {
            answerContent = "1 answer";
        }
        if (answerCount > 1) {
            answerContent = answerCount + " answers";
        }

        if (questionCount > 0 && answerCount === 0) {
            return questionContent;
        }
        if (questionCount === 0 && answerCount > 0) {
            return answerContent;
        }

        if (questionCount > 0 && answerCount > 0) {
            return questionContent + ", " + answerContent;
        }

        return null;
    };

    const handleDelete = async () => {
        try {
            await deleteById("/api/userProfile", id);
            localStorage.removeItem("ask_token");
            history.push("/");

        } catch (error) {
            console.log(error);
            setShow(false);
            if (error.response.status === 500) {
                setErrorMessage(
                    "We can't delete all of your content. Please try to delete, questions, answers and comments individually and try again. " +
                    "If that doesn't work get in touch with the PG Hub and we will do it for you");

                setError(true);
            } else {
                setErrorMessage("We have experienced an error. Please contact the PG Hub to report this.");
                setError(true);
            }
        }
    };

    return <div className="full-height-reset">
        {error && <Alert variant="warning">{errorMessage}</Alert>}
        {profile !== null && profile !== undefined && <Row>
            <Col md={12}><h3>{profile.fullName}</h3></Col>
            <Col md={3}>
                {profile.imageName !== null && <Image src={`/images/${profile.imageName}?w=200`} fluid/>}

                <dl>
                    {profile.courseDepartment &&
                        <><dt>Course</dt>
                            <dd>{profile.courseDepartment}</dd></>
                    }
                    {profile.yearMode && <>
                        <dt>Year and mode of study</dt>
                        <dd>{profile.yearMode}</dd>
                    </>}
                    {profile.homeCountry && <>
                        <dt>Home country</dt>
                        <dd>{profile.homeCountry}</dd>
                    </>
                    }
                </dl>
                {(currentUser.sub === profile.userName) &&
                    <p>
                        <Link to={`/profile/user/edit/${profile.id}`}>Edit profile</Link><br />
                        <Button variant="light" size="sm" className="mt-4" onClick={handleShow}>Delete profile</Button>
                    </p>
                }

            </Col>
            <Col md={9}>

                <p>{activity()}
                </p>
                <Nav fill variant="tabs" defaultActiveKey="#" className="mt-3 mb-3">
                    <Nav.Item>
                        <Nav.Link href="#" onClick={toggleCat}>Questions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-2" onClick={toggleCat}>Answers</Nav.Link>
                    </Nav.Item>
                </Nav>
                {cat === "questions" && profile.questions.length > 0 &&
                    <Table>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Added</th>
                                <th>Votes</th>
                                <th>Answers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {profile.questions.map(q => {
                                return <tr key={q.questionId}>
                                    <td>{q.title}</td>
                                    <td><DateFormat date={q.questionDate} /></td>
                                    <td>{q.questionVotes}</td>
                                    <td>{q.answerCount}</td>
                                </tr>;
                            })
                            }
                        </tbody>
                    </Table>
                }
                {cat === "answers" && profile.answers.length > 0 &&
                    <Table>
                        <thead>
                            <tr>
                                <th>Question</th>
                                <th>Answered</th>
                                <th>Votes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {profile.answers.map(a => {
                                return <tr key={a.answerId}>
                                    <td>{a.title}</td>
                                    <td><DateFormat date={a.answerDate} /></td>
                                    <td>{a.answerVotes}</td>
                                </tr>;
                            })
                            }
                        </tbody>
                    </Table>

                }
                {profile.mentions.length > 0 &&
                    <div><h5>Latest mentions</h5>
                        <MentionBlock mentions={profile.mentions} previousLogin={profile.previousLogin} />
                    </div>
                }
            </Col>



        </Row>
        }
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>Deleting a profile will remove all contributions, questions answers etc. linked to that profile. Are you sure?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Confirm delete
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;
}

export default UserProfile;