import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
//import Table from "react-bootstrap/Table";
import BTable from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTable, useFilters, useSortBy } from "react-table";
import { useParams, useHistory, Link } from "react-router-dom";
import { getAllData, postNoBody, deleteById } from "../shared/api-requests";
import { DateFormat } from "../shared/utils";
import AsyncSelect from 'react-select/async';

const Admins = () => {
    const [data, setData] = useState([]);

    const [showAdd, setShowAdd] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [adminSelected, setAdminSelected] = useState({ label: "", value: "" });

    const handleCloseAdd = () => {
        setShowAdd(false);
        setAdminSelected({ label: "", value: "" });

    };
    const handleShowAdd = () => {
        setAdminSelected({ label: "", value: "" });
        setShowAdd(true);
    };

    const loadAdmins = async () => {
        try {
            const resp = await getAllData("/api/admins");
            setData(resp);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadAdmins();
    }, []);

    const addAdmin = async () => {
        const id = parseInt(adminSelected.value, 10);
        try {
            await postNoBody(`/api/admins/${id}`);
            setShowAdd(false);
            loadAdmins();
        } catch (e) {
            console.log(e);
        }
    }

    const handleAdminSelect = (value) => {
        console.log(value);
        if (value === null) {
            setAdminSelected({ label: "", value: "" });
        } else {
            setAdminSelected(value);
        }
    };

   

    const loadUserOptions = async (term, callback) => {
        try {
            if (term.length > 3) {
                const options = await getAllData(`/api/adminLookup?q=${term}`);
                callback(options);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleUserChange = (newValue) => {
        setUserInput(newValue);

    };

    const removeAdmin = async (id) => {
        try {
            await deleteById("/api/admins", id);
            loadAdmins();
        } catch (e) {
            console.log(e);
        }
    }

    const actionCell = (dt) => {
        return <span style={{ display: "inline-block", minWidth: 140 }}>
            <Button variant="danger" onClick={() => removeAdmin(dt.cell.value)}>Remove</Button>
        </span>;
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Full name",
                accessor: "fullName"
            },
            {
                Header: "Actions",
                accessor: "id",
                disableFilters: true,
                disableSortBy: true,
                Cell: actionCell
            }
        ],
        []
    );

    // Define a default UI for filtering
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }) {
        const count = preFilteredRows.length;

        return (
            <Form.Control
                value={filterValue || ""}
                onChange={e => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} records...`}
            />
        );
    };

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data, defaultColumn }, useFilters, useSortBy);

    return <div className="full-height-reset">
        <Modal show={showAdd} onHide={handleCloseAdd}>
            <Modal.Header closeButton>
                <Modal.Title>Add admin</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AsyncSelect 
                    placeholder="Start typing a first name"
                    isClearable
                    cacheOptions
                    loadOptions={loadUserOptions}
                    defaultOptions
                    onInputChange={handleUserChange}
                    onChange={handleAdminSelect}
                />
                {adminSelected.label !== "" && <p>{adminSelected.label} selected.</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={addAdmin}>
                    Add admin
                </Button>
                <Button variant="secondary" onClick={handleCloseAdd}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
        <Row>
            <Col md={12}>
                <h2>Assign admins</h2>
                <Button variant="light" onClick={handleShowAdd}>Add admin</Button>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <BTable striped bordered size="sm" {...getTableProps()} responsive>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th{...column.getHeaderProps()}>
                                        <div>
                                            <span {...column.getSortByToggleProps()}>
                                                {column.render("Header")}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? " 🔽"
                                                        : " 🔼"
                                                    : ""}
                                            </span>
                                        </div>
                                        <div>
                                            {column.canFilter ? column.render("Filter") : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </BTable>
            </Col>
        </Row>
    </div>;

};

export default Admins;