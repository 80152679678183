import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
//import Table from "react-bootstrap/Table";
import BTable from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useTable, useFilters, useSortBy } from "react-table";
import { useParams, useHistory, Link } from "react-router-dom";
import { getAllData, postBodyReturnResponse } from "../shared/api-requests";
import { DateFormat } from "../shared/utils";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AsyncSelect from 'react-select/async';

const Mentors = () => {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState("Active");
    const [showChange, setShowChange] = useState(false);
    const [userSelected, setUserSelected] = useState({ label: "", value: "" });
    const [selectedRole, setSelectedRole] = useState("Mentor");
    const [isMentor, setIsMentor] = useState(false);
    const [isUser, setIsUser] = useState(false);

    const handleCloseChange = () => {
        setShowChange(false);
        setUserSelected({ label: "", value: "" });

    };
    const handleShowChange = () => {
        setUserSelected({ label: "", value: "" });
        setShowChange(true);
    };

    const handleUserSelect = (value) => {
        setIsMentor(false);
        setIsUser(false);
        if (value === null) {
            setUserSelected({ label: "", value: "" });
        } else {
            setUserSelected(value);
        }
    };

    const loadMentors = async () => {
        try {
            const resp = await getAllData(`/api/mentor/${status}`);
            setData(resp);

        } catch (error) {
            console.log(error);
        }
    };

    const changeRole = async () => {
        const userId = parseInt(userSelected.value, 10);
        var payload = { userId: userId, role: selectedRole };
       // figure out if the user is already a mentor or not.
       const mentorExists = data.find(d => d.id === userId);
       if (selectedRole === "Mentor" && mentorExists !== undefined) {
           setIsMentor(true);
           return;
       }

       if (selectedRole === "User" && mentorExists === undefined) {
           setIsUser(true);
           return;
       }

        try {
            await postBodyReturnResponse("/api/editRole", payload);
            loadMentors();
            setShowChange(false);

        } catch (e) {
            console.log(e);
        } 
    };

    const handleSelectedRole = (event) => {
        setIsMentor(false);
        setIsUser(false);
        setSelectedRole(event.target.value);
    };

    useEffect(() => {
        loadMentors();
    }, [status]);

    const dateCell = (dt) => {
        return <span style={{ display: "inline-block", minWidth: 130 }}><DateFormat date={dt.cell.value} /></span>;
    };

    const activeCell = (dt) => {
        return <span>{dt.cell.value.toString()}</span>;
    };

    const actionCell = (dt) => {
        return <span style={{ display: "inline-block", minWidth: 140 }}>
            <Link to={`/mentor/${dt.cell.value}`}>View</Link>{" "}|{" "}
            <Link to={`/profile/mentor/edit/${dt.cell.value}`}>Edit</Link>{" "}
          |{" "}<Link to={`/profile/mentor/delete/${dt.cell.value}`}>Delete</Link>
        </span>;
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Full name",
                accessor: "fullName"
            },
            {
                Header: "Created",
                accessor: "created",
                disableFilters: true,
                Cell: dateCell
            },
            {
                Header: "Modified",
                accessor: "modified",
                disableFilters: true,
                Cell: dateCell
            },
            {
                Header: "Active",
                accessor: "active",
                disableFilters: true,
                disableSortBy: true,
                Cell: activeCell
            },
            {
                Header: "Actions",
                accessor: "id",
                disableFilters: true,
                disableSortBy: true,
                Cell: actionCell
            }
        ],
        []
    );

    const handleActive = (event) => {
        setStatus(event.target.value);
    };

    const loadUserOptions = async (term, callback) => {
        try {
            if (term.length > 3) {
                const options = await getAllData(`/api/adminLookup?q=${term}`);
                callback(options);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Define a default UI for filtering
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }) {
        const count = preFilteredRows.length;

        return (
            <Form.Control
                value={filterValue || ""}
                onChange={e => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} records...`}
            />
        );
    };

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data, defaultColumn }, useFilters, useSortBy);

    return <div className="full-height-reset">
               <Modal show={showChange} onHide={handleCloseChange}>
                   <Modal.Header closeButton>
                       <Modal.Title>Change role</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>
                <p>Change role from user to mentor or vice-versa</p>
                       <AsyncSelect 
                           placeholder="Start typing a first name"
                           isClearable
                           cacheOptions
                           loadOptions={loadUserOptions}
                           defaultOptions
                           onChange={handleUserSelect}
                       />
                       {userSelected.label !== "" && <p>{userSelected.label} selected.</p>}
                       <Form.Group controlId="SelectRole">
                           <Form.Label>Select role</Form.Label>
                           <Form.Control as="select" onChange={handleSelectedRole} value={selectedRole}>
                               <option>Mentor</option>
                               <option>User</option>
                           </Form.Control>
                       </Form.Group>
                {isMentor &&   <Alert variant="warning">Already a mentor</Alert>}
                {isUser &&   <Alert variant="warning">Not a mentor</Alert>}
              
                   </Modal.Body>
                   <Modal.Footer>
                       <Button variant="primary" onClick={changeRole}>
                          Change role
                       </Button>
                       <Button variant="secondary" onClick={handleCloseChange}>
                           Cancel
                       </Button>
                   </Modal.Footer>
               </Modal>
        <Row>
            <Col md={12}>
                <h2>Mentors</h2>
                <Link to="/profile/mentor/create">Add mentor</Link> <br/>
                <Button variant="light" size="sm" onClick={handleShowChange}>Change user role</Button>
            </Col>
        </Row>

        <Row>
            <Col md={2}>
                <Form.Group>
                    <Form.Label>Show</Form.Label>
                    <Form.Control as="select" defaultValue={status} onChange={handleActive}>
                        <option>Active</option>
                        <option>Inactive</option>
                        <option>All</option>
                    </Form.Control>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <BTable striped bordered size="sm" {...getTableProps()} responsive>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th{...column.getHeaderProps()}>
                                        <div>
                                            <span {...column.getSortByToggleProps()}>
                                                {column.render("Header")}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? " 🔽"
                                                        : " 🔼"
                                                    : ""}
                                            </span>
                                        </div>
                                        <div>
                                            {column.canFilter ? column.render("Filter") : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </BTable>
            </Col>
        </Row>
    </div>;

};

export default Mentors;