import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { getAllData, postBodyReturnResponse, putBody, deleteById } from "../shared/api-requests";

const types = [
    { name: "Safeguarding", id: 0 },
    { name: "Abuse", id: 1 }
];
const WarningForm = ({ payload, method, handleFieldChange, }) => {
    return <Form onSubmit={method}>
        <Form.Group controlId="formTerm">
            <Form.Label>Term</Form.Label>
            <Form.Control type="text" placeholder="Enter term" value={payload.term
            } name="term" onChange={handleFieldChange} />
        </Form.Group>
        <Form.Group controlId="formType">
            <Form.Label>Type</Form.Label>
            <Form.Control as="select" value={payload.typeId} onChange={handleFieldChange} name="typeId">
                <option>--Select type--</option>
                {types.map(t => {
                    return <option key={t.id} value={t.id}>{t.name}</option>;
                })}
            </Form.Control>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={payload.term === "" || payload.typeId === ""
        }>
            Submit
        </Button>
    </Form>;
};

const WarningSigns = () => {
    const [data, setData] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [payload, setPayload] = useState({ term: "", type: "", id: 0, typeId: "" });
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseAdd = () => setShowAdd(false);
    const handleShowAdd = () => setShowAdd(true);
    const handleCloseDelete = () => setShowDelete(false);

    const handleShowDelete = (event) => {
        setShowDelete(true);
        const found = data.find(element => element.id === parseInt(event.target.value, 10));
        const editedPayload = { ...payload, ...found };
        setPayload(editedPayload);
    };


    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = (event) => {
        setShowEdit(true);
        // rather than make a round trip to the server, load from the data
        // we already have
        const found = data.find(element => element.id === parseInt(event.target.value, 10));
        const editedPayload = { ...payload, ...found };
        setPayload(editedPayload);
    };

    const loadData = async () => {
        try {
            const resp = await getAllData("/api/warningSigns");
            setData(resp);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const handleFieldChange = (event) => {
        const editedPayload = { ...payload, [event.target.name]: event.target.value };
        setPayload(editedPayload);
    };

    const handleSubmit = async (event) => {
        handleCloseAdd();
        event.preventDefault();
        payload.typeId = parseInt(payload.typeId, 10);
        payload.term = payload.term.toLowerCase();
        try {
            await postBodyReturnResponse("/api/warningSigns", payload);
            loadData();
        } catch (e) {
            console.log(e);
        }
    };

    const handleEdit = async (event) => {
        handleCloseEdit();
        event.preventDefault();
        payload.typeId = parseInt(payload.typeId, 10);
        payload.term = payload.term.toLowerCase();
        try {
            await putBody("/api/warningSigns", payload);
            loadData();
        } catch (e) {
            console.log(e);
        }
    };

    const handleDelete = async () => {
        handleCloseDelete();
        try {
            await deleteById("/api/warningSigns", payload.id);
            loadData();
        } catch (e) {
            console.log(e);
        }
    };
    return <div className="full-height-reset"><Row><Col md={12}>
        <h2>Warning signs</h2>
        <Button variant="light" className="mb-2" onClick={handleShowAdd}>Add term</Button>
        <p className="text-muted">Terms with uses cannot be edited or deleted</p>
        <Table>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Term</th>
                    <th>Uses</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {data.map(d => {
                    return <tr key={d.id}>
                        <td>{d.type}</td>
                        <td>{d.term}</td>
                        <td>{d.uses}</td>
                        <td>
                            <Button variant="light" value={d.id} onClick={handleShowEdit} disabled={d.uses > 0}>Edit</Button>{" "}
                            <Button variant="light" value={d.id} onClick={handleShowDelete} disabled={d.uses > 0}>Delete</Button>
                        </td>
                    </tr>;
                })}
            </tbody>
        </Table></Col>
        <Modal show={showAdd} onHide={handleCloseAdd}>
            <Modal.Header closeButton>
                <Modal.Title>Add new term</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <WarningForm payload={payload} method={handleSubmit} handleFieldChange={handleFieldChange} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAdd}>
                    Cancel
                </Button>

            </Modal.Footer>
        </Modal>
        <Modal show={showEdit} onHide={handleCloseEdit}>
            <Modal.Header closeButton>
                <Modal.Title>Edit term</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <WarningForm payload={payload} method={handleEdit} handleFieldChange={handleFieldChange} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEdit}>
                    Cancel
                </Button>

            </Modal.Footer>
        </Modal>
        <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
                <Modal.Title>Delete term</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this?</p>
                <ul>
                    <li>{payload.term}</li>
                    <li>{payload.type}</li>
                </ul>
                <Button variant="danger" onClick={handleDelete}>Delete</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDelete}>
                    Cancel
                </Button>

            </Modal.Footer>
        </Modal>
    </Row></div>;
};
export default WarningSigns;
