import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Conditions = () => {
    return <Row style={{backgroundColor:"white"}}>
        <Col md={{ span: 8, offset: 2 }} >
            <h2>Conditions of use</h2>

            <p>
                The Postgrad mentoring site is a community tool – please treat others as you would like to be treated yourself.
        </p>
            <p>
                The aim of this community forum is to build peer-to-peer connections - providing support to our postgraduate community through mentoring,
                where students can hear the experiences of current and recently graduated Warwick Postgrads.
                We aim to create a friendly, safe and positive environment. It is therefore, important that all of our users are respectful to others both within the
                open 'Conversation' and within private messages to 'Mentors'.
        </p>

            <p>
                The site is overseen by our team of PG Mentors, who will respond to direct questions and also add to the group conversations.
                Staff do not monitor the site 24 hours a day or at weekends.
        </p>
            <p>
                If users feel the site is being misused or inappropriate language or conversations are taking place,
            we please ask that you report it to the <a href="mailto:pgcommunity@warwick.ac.uk">PG Community Engagement Coordinator</a>.
        </p>
            <p>
                Although all conversations and information within the Postgrad mentoring site is provided by students (or recent graduates),
                for the benefit of students, all users should be mindful that this is an official University of Warwick site,
            so users are expected to follow the <a href="http://www2.warwick.ac.uk/students/studentbehaviour">core guidelines and principles</a> as laid out by the University.
        </p>

            <h3>
                Further conditions of use for the site
        </h3>
            <h4>No Spamming or advertising</h4>

            <p>
                This includes posting the same item multiple times, sending lots of unwanted emails and advertising products
                or activities which are not relevant to the conversation/question.
        </p>
            <p>If you are spammed then let us know by sending the <a href="mailto:pgcommunity@warwick.ac.uk">PG Community Engagement Coordinator</a> a message.</p>

            <h4>Please use English</h4>
            <p>
                Although we are proud of the diversity of our students and celebrate that they bring with them a variety of different languages,
                we have to make sure that all content on this website is in English. Far from wanting to stifle internationality,
                this is because with such an international member base, we simply cannot make sure that we understand enough languages
                to effectively safeguard members and keep offensive language and content off the site. It's unfortunate, but it's our only option!
        </p>

            <h4>No to bullying</h4>
            <p>
                We do not tolerate bullying, harassment or any anti-social behaviour, so if you ever feel uncomfortable,
            don’t respond, and let the <a href="mailto:pgcommunity@warwick.ac.uk">PG Community Engagement Coordinator</a> know about it straight away.
        </p>
        </Col>
    </Row>;
};

export default Conditions;