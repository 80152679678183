import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { DateFormat } from "./utils";
import TextEditor from "./text-editor";
import AuthorLink from "./author-link";

const CommentBlock = (props) => {
    const [open, setOpen] = useState(false);
    const [contentEditor, setContentEditor] = useState();
    const [editedContent, setEditedContent] = useState();
    const [anon, setAnon] = useState(false);
    const [editId, setEditId] = useState();
    const [deleteId, setDeleteId] = useState();
    const [showModal, setShowModal] = useState(false);
    const editorHeight = "200";

    const handleEditorChange = (content) => {
        setContentEditor(content);
    }

    const handleAnon = (event) => {
        setAnon(event.target.checked);
    }

    const openEdit = (e) => {
        setEditId(parseInt(e.target.value, 10));
    };

    const handleCloseForm = () => {
        setAnon(false);
        setOpen(false);
        setContentEditor("");
    }

    const submitComment = () => {
        props.add(contentEditor, anon, props.apiUrl, props.parentId);
        handleCloseForm();
    };

    const editComment = () => {
        props.edit(editedContent, editId, props.apiUrl);
        setEditId(0);
    };

    const confirmDelete = (event) => {
        setDeleteId(event.target.value);
        setShowModal(true);
    };

    const handleDelete = () => {
        props.deleteComment(parseInt(deleteId, 10), props.apiUrl);
        setShowModal(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setDeleteId(0);
    };

    return <div style={{ width: "90%", marginLeft: "5%", fontSize: "0.9em" }}>
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Delete comment</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this comment?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
        <h5>{props.title}{" "}comments</h5>
        {props.comments.map(c => {
            return <div key={c.id} id={`${props.title.toLowerCase()}comment-${c.id}`}>
                {editId !== c.id && <div>
                    <p dangerouslySetInnerHTML={{ __html: c.body }}></p>
                    <p className="text-muted">Added  <DateFormat date={c.created} /> by <AuthorLink author={c.author} />
                        {c.modified !== null && <span>{", "}edited <DateFormat date={c.modified}/> by <AuthorLink author={c.editor} /></span>}</p>
                </div>
                }
                {/* The conditionally rendered content editor for a comment edit*/}
                {
                    editId === c.id &&
                    <div>
                        <TextEditor contentEditor={editedContent} handleEditorChange={setEditedContent} initialValue={c.body} height={editorHeight} />
                        <p><Button size="sm" value={c.id} onClick={editComment}>Submit</Button>{" "}
                            <Button size="sm" variant="secondary" value={0} onClick={openEdit}>Cancel</Button>
                        </p>
                    </div>
                }
                {
                  c.canEdit  &&
                    <p>
                        <Button variant="outline-secondary" size="sm" value={c.id} onClick={openEdit}>Edit</Button>{" "}
                        <Button variant="outline-danger" size="sm" value={c.id} onClick={confirmDelete}>Delete</Button>
                    </p>
                }
                <hr />
            </div>;
        })}
        <Button variant="outline-secondary"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
        >Add a comment</Button>
        <Collapse in={open}>
            <div id="example-collapse-text" className="mt-2">
                <TextEditor contentEditor={contentEditor} handleEditorChange={handleEditorChange} initialValue={null} height={editorHeight} />
                <Form.Check
                    type="checkbox"
                    label="Be anonymous"
                    id="anon-check"
                    checked={anon}
                    onChange={handleAnon}
                />
                <Button className="mt-2" onClick={submitComment}>Submit</Button>
            </div>
        </Collapse>
    </div>;
};

export default CommentBlock;